/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import update from "immutability-helper";
import {
  trackSelectCategoriesEventAction,
  trackSelectDanceLevelEventAction,
  trackSelectDanceReasonEventAction,
} from "modules/onboarding";
import toSentence from "helpers/toSentence";
import Flex from "app/components/Flex";
import Div from "app/components/Div";
import Button from "app/components/Button";
import LoaderOverlay from "app/components/Loader/LoaderOverlay";
import { useFollowCategoriesMutation } from "hooks/useFollowCategories";
import {
  useGetOnboardingDataQuery,
  useGetCategoriesQuery,
  useSetDanceReasonPreferenceMutation,
  useSetDanceLevelPreferenceMutation,
} from "services/graphql";
import Option from "./Option";
import Section from "./Section";

const FROM_MODULE = "Settings";

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const OptionsDiv = styled(Div)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
`;

const OptionsButton = styled(Button)`
  font-size: 12px;
  width: 180px;
  margin-bottom: 24px;
  margin-top: 8px;
  height: 35px;
  padding: 0;
`;

const DancePreferences = () => {
  const { userPreferences = { duration: {} } } = useSelector(({ user }) => ({
    userPreferences: user.private && user.private.dance_preferences,
  }));
  const dispatch = useDispatch();
  const { data: { onboardingData } = {} } = useGetOnboardingDataQuery({
    fetchPolicy: "no-cache",
  });
  const { data: { categories } = {} } = useGetCategoriesQuery({
    variables: {
      slugs: [],
    },
  });
  const [setDanceReasonPreference] = useSetDanceReasonPreferenceMutation();
  const [setDanceLevelPreference] = useSetDanceLevelPreferenceMutation();
  const setCategories = useFollowCategoriesMutation();

  const [editing, setEditing] = useState(
    !userPreferences.level
      ? "level"
      : !userPreferences.reason
      ? "reason"
      : !userPreferences.styles
      ? "categories"
      : null
  );

  const [
    { selectedReason, selectedLevel, selectedCategories, selectedDuration },
    setSelectedPreferences,
  ] = useState({
    selectedReason: userPreferences.reason || null,
    selectedLevel: userPreferences.level || null,
    selectedCategories: userPreferences.styles || {},
    selectedDuration: userPreferences?.duration?.slug || null,
  });

  const [isSaving, setIsSaving] = useState(false);

  const saveLevel = () => {
    setIsSaving(true);
    setDanceLevelPreference({
      variables: {
        slug: selectedLevel,
      },
    }).then(() => {
      dispatch(
        trackSelectDanceLevelEventAction({
          danceLevel: selectedLevel,
          module: FROM_MODULE,
        })
      );
      setIsSaving(false);
      setEditing(selectedReason ? null : "reason");
    });
  };

  const saveReason = () => {
    setIsSaving(true);
    setDanceReasonPreference({
      variables: {
        slug: selectedReason,
      },
    }).then(() => {
      dispatch(
        trackSelectDanceReasonEventAction({
          danceReason: selectedReason,
          module: FROM_MODULE,
        })
      );
      setIsSaving(false);
      setEditing(Object.keys(selectedCategories).length ? null : "categories");
    });
  };

  const saveCategories = () => {
    setIsSaving(true);
    setCategories({
      variables: {
        categories: _.map(selectedCategories, (isFollowing, slug) => ({
          slug,
          isFollowing,
        })),
      },
    })
      .then(() => {
        dispatch(
          trackSelectCategoriesEventAction({
            selectedCategories: Object.keys(selectedCategories).filter(
              slug => selectedCategories[slug]
            ),
            module: FROM_MODULE,
          })
        );
        setIsSaving(false);
        setEditing(selectedDuration ? null : "duration");
      })
      .catch(() => ({
        error: { message: "An error occurred saving your preferences." },
      }));
  };

  const toggleCategory = slug => {
    setSelectedPreferences(prevState => ({
      ...prevState,
      selectedCategories: update(prevState.selectedCategories, {
        [slug]: { $set: !prevState.selectedCategories[slug] || false },
      }),
    }));
  };

  const toggleEditing = type => {
    setEditing(prevEditing => (prevEditing === type ? null : type));
  };

  if (!onboardingData || !categories) {
    return null;
  }

  const userExperience = onboardingData.levelOptions.find(
    ({ slug }) => userPreferences.level === slug
  );
  const userReason = onboardingData.reasonOptions.find(
    ({ slug }) => userPreferences.reason === slug
  );

  return (
    <Flex width={{ _: "100%", md: "60%" }} flexDirection="column">
      {isSaving && <LoaderOverlay />}
      <Section
        title="My Experience"
        preference={userExperience && userExperience.name}
        editing={editing === "level"}
        toggleEditing={() => toggleEditing("level")}
      />
      {editing === "level" && (
        <OptionsDiv>
          <OptionsWrapper>
            {_.map(onboardingData.levelOptions, level => (
              <Option
                key={level.slug}
                onClick={() =>
                  setSelectedPreferences(prevState => ({
                    ...prevState,
                    selectedLevel: level.slug,
                  }))
                }
                selected={selectedLevel === level.slug}
                name={level.name}
              />
            ))}
          </OptionsWrapper>
          <OptionsButton onClick={saveLevel}>Save Changes</OptionsButton>
        </OptionsDiv>
      )}
      <Section
        title="My Goal"
        preference={userReason && userReason.goal}
        editing={editing === "reason"}
        toggleEditing={() => toggleEditing("reason")}
      />
      {editing === "reason" && (
        <OptionsDiv>
          <OptionsWrapper>
            {_.map(onboardingData.reasonOptions, reason => (
              <Option
                key={reason.slug}
                onClick={() =>
                  setSelectedPreferences(prevState => ({
                    ...prevState,
                    selectedReason: reason.slug,
                  }))
                }
                selected={selectedReason === reason.slug}
                name={reason.goal}
              />
            ))}
          </OptionsWrapper>
          <OptionsButton onClick={saveReason}>Save Changes</OptionsButton>
        </OptionsDiv>
      )}
      <Section
        title="Categories I'm Interested In"
        preference={toSentence(
          categories.reduce((arr, category) => {
            const isFollowing = userPreferences.styles?.[category.slug];
            if (isFollowing) {
              return [...arr, category.name];
            }

            return arr;
          }, [])
        )}
        editing={editing === "categories"}
        toggleEditing={() => toggleEditing("categories")}
      />
      {editing === "categories" && (
        <OptionsDiv>
          <OptionsWrapper>
            {_.map(categories, category => (
              <Option
                key={category.slug}
                onClick={() => toggleCategory(category.slug)}
                selected={selectedCategories[category.slug]}
                name={category.name}
              />
            ))}
          </OptionsWrapper>
          <OptionsButton onClick={saveCategories}>Save Changes</OptionsButton>
        </OptionsDiv>
      )}
    </Flex>
  );
};

export default DancePreferences;
