import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Check } from "app/components/Icon";

const Wrapper = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.black};
  padding: 20px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Name = styled.p`
  font-size: 14px;
  margin: 0;
  font-weight: 500;
  margin-bottom: 5px;
`;

const Icon = styled.div`
  width: 15px;
  color: ${({ theme }) => theme.colors.lightGrey};

  ${({ selected, theme }) =>
    selected &&
    `
    color: ${theme.colors.primary[0]};
  `}
`;

const Option = ({ name, selected, ...props }) => (
  <Wrapper {...props}>
    <div>
      <Name>{name}</Name>
    </div>
    <Icon as={Check} selected={selected} />
  </Wrapper>
);

Option.defaultProps = {
  selected: false,
};

Option.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

export default Option;
